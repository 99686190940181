<template>
    <div class="documents">
        <div class="form-container">
            <h1>{{PageTitle}}</h1>
            <div :class="['fatca-container', {'twocols' : fatcaCards && fatcaCards.length > 1}]">
                <div v-if="!!actual" class="fatca-cards">
                    <rwm-document-card
                            :download-link="actual.scan ? actual.scan : true"
                            :title="cardTitle"
                            :issue="actual.issue"
                            :is-active="!!actual"
                            :status="actual.status"
                            :action="actual.action"
                            :message="actual.comment_reject"
                            :messages="actual.alerts"
                            :admin-comment="actual.comment_reject"
                            :sing-date="date(actual.date_sign)"
                            @download="toDownload(actual)"
                    />
                </div>
<!--                color RWM/Grey-->
                <div class="row">
                    <div v-if="!(!!actual)" class="col-12">
                        <p class="anketa">У вас нет отправленных документов{{hasDraft ? " ,подтвердите, пожалуйста, данные указанные в анкете:" : "."}}</p>
                    </div>
                    <div class="col-3">
                        <rwm-button outlined width="100%" @click="$emit('fillOut')">{{hasDraft ? "Подтвердить данные" : "Заполнить анкету"}}</rwm-button>
                    </div>
                </div>
                <div :class="['fatca-info', {'onecol' : fatcaCards && fatcaCards.length <= 1}]">
                    <rwm-info
                            msg="При повторном нажатии на кнопку «Заполнить анкету» текущая анкета будет автоматически перенесена в архив"
                            icon="warning"
                            color="#DA291C"
                    />
                    <rwm-info
                            v-if="infoText"
                            :msg="infoText"
                            icon="warning"
                            color="#DA291C"
                    />
                    <p class="anketa" v-html="infoBlock" v-if="infoBlock" />
                </div>
                <div v-if="fatcaCards && fatcaCards.length" class="fatca-cards">
                    <rwm-document-card
                            v-for="card in fatcaCards"
                            :key="card.id"
                            :download-link="card.scan ? card.scan : true"
                            :title="cardTitle"
                            :issue="card.issue"
                            :status="card.status"
                            :action="card.action"
                            :sing-date="date(card.date_sign)"
                            @download="toDownload(card)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RwmInfo from "../../../RWMInfo/RwmInfo";
    import RwmDocumentCard from "../../../RWMDocumentCard/RwmDocumentCadr";
    import RwmButton from "../../../RWMButton/RwmButton";
    import dateFormatter from "../../../../modules/module.date";
    export default {
        name: "FATCA",
        components: {RwmButton, RwmDocumentCard, RwmInfo},
        props: {
            PageTitle: String,
            infoText: String,
            infoBlock: String,
            cardTitle: String,
            fatcaCards: {
                type: Array,
                default: () => []
            },
            hasDraft: Boolean,
            actual: Object,
        },
        methods: {
            date(date) {
                return dateFormatter(date)
            },
          toDownload(card) {
              if (card.scan) {
                const name = card.scan.text_name ? card.scan.text_name + card.origin_name.split(".")[1] : card.scan.origin_name
                this.$emit('download-scan', {name: name, id: card.scan.id})
              } else {
                this.$emit('download', card.id)
              }
          }
        }
    }
</script>

<style scoped lang="scss">
    .fatca-container {
        row-gap: 24px;
        display: flex;
        flex-direction: column;
        &.twocols {
            @media screen and (min-width: 1600px){
                display: flex;
                column-gap: 44px;
                max-width: initial;
                & .fatca-info {
                    margin-top: -24px;
                }
            }
        }
        & .fatca-cards {
            display: flex;
            column-gap: 24px;
            row-gap: 24px;
            flex-wrap: wrap;
        }
        & .fatca-info {
            &.onecol {
                max-width: 718px;
            }
        }
    }
</style>